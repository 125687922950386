<template>
  <div id="app">
    <router-view/>
    <div @click="scrollToTop" id="back-to-top"><i class="el-icon-arrow-up"></i></div>
  </div>
</template>

<script>
export default {
  created () {
   
    window.addEventListener('scroll', this.handleScroll);
  },
  watch: {
    $route: {
        immediate: true,
        handler(to) {
            document.title = to.meta.title || 'CALP';
        }
    },
},
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    scrollToTop() {
      window.scrollTo(0,0);
    },
    handleScroll () {

      var element = document.getElementById('back-to-top');
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        element.style.display = "flex";
      } else {
        element.style.display = "none";
      }
    }
  }
}
</script>