import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'page',
    component: () => import('@/views/Page.vue'),
    meta: {
      title: "CALP | หน้าแรก",
    }
  },
  {
    path: '/project/:id',
    name: 'project',
    component: () => import('@/views/Project.vue'),
    meta: {
      title: "CALP | โครงการ",
    }
  
  },
  {
    path: '/community/:id',
    name: 'community',
    component: () => import('@/views/Comunity.vue'),
    meta: {
      title: "CALP | ชุมชน",
    }
  },
  {
    path: '/Product/:id',
    name: 'product',
    component: () => import('@/views/Product.vue'),
    meta: {
      title: "CALP | ผลิตภัณฑ์/ผลงาน",
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
